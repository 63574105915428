import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "@ui/button";
import Image from "@ui/image";
import { ImageType } from "@utils/types";

const SkillCard = ({
    timeRange,
    title,
    subtitle,
    desc,
    path,
    image,
    className,
    bullets,
    ...restProps
}) => {
    return (
        <div className={cn("experience-style-two", className)} {...restProps}>
            <div className="experience-left">
                {image?.src && (
                    <div className="experience-image">
                        <Image src={image.src} alt={image?.alt || title} />
                    </div>
                )}
            </div>

            <div className="experience-center">
                    <div className="row">

                    {title && <span className="experience-title">{title}</span>}
                    {subtitle && <span className="subtitle">{subtitle}</span>}
                    {timeRange && <span className="date">{timeRange}</span>}

                    </div>
                    {bullets && 
                    <div className="row">
                        <ul>
                            {bullets.map((bullet) => <li>
                                {bullet}
                            </li>)}
                        </ul>
                    </div>}
                </div>
        </div>
    );
};

SkillCard.propTypes = {
    timeRange: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    desc: PropTypes.string,
    path: PropTypes.string,
    image: PropTypes.shape(ImageType),
    className: PropTypes.string,
    bullets: PropTypes.arrayOf(PropTypes.string)
};

export default SkillCard;
