import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ProgressBar from "@ui/progress-bar";
import { InnerType, SectionTitleType } from "@utils/types";
import ContentTitle from "@components/content-title";
import SectionTitle from "@components/section-title";

const SkillArea = ({ data }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    {data?.section_title && (
                        <SectionTitle
                            {...data.section_title}
                            className="text-center"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="100"
                            data-aos-once="true"
                        />
                    )}
                </div>
            </div>
            <div className="personal-experience-inner mt--40">
                <div className="row row--40 mb--40">
                    {data?.inner?.map((content, i) => (
                        <div
                            className={cn(
                                "col-lg-6 col-md-6 col-12 mb--40",
                                i !== 0 && "mt_sm--60"
                            )}
                            key={content.id}
                        >
                            <div className="progress-wrapper">
                                <div className="content">
                                    {content?.section_title && (
                                        <ContentTitle
                                            {...content.section_title}
                                        />
                                    )}

                                    {content?.items?.map((item) => (
                                        <ProgressBar
                                            key={item.id}
                                            title={item.title}
                                            value={item.value}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

SkillArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        inner: PropTypes.arrayOf(PropTypes.shape(InnerType)),
    }),
};

SkillArea.defaultProps = {
    id: "skills",
};

export default SkillArea;
