import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { X, ThumbsUp, ChevronRight } from "react-feather";
import Image from "@ui/image";
import Button from "@ui/button";
import { ImageType, TextType } from "@utils/types";

const PortfolioModal = ({
    show,
    setShow,
    image,
    title,
    path,
    description,
    tags,
    client
}) => {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered={true}
        >
            <Modal.Header>
                <Modal.Title
                    id="example-custom-modal-styling-title"
                    className="sr-only"
                >
                    Custom Modal Styling
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShow(false)}
                >
                    <span aria-hidden="true">
                        <X />
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div >

                            <div className="row">

                            {image?.src && (
                                <div className="company-logo">
                                    <Image
                                        src={image.src}
                                        alt={image?.alt || title}
                                    />
                                </div>
                            )}
                            <h3>
                                {client} - {title}
                            </h3>
                            </div>
                                <p
                                >
                                    {description}
                                </p>
                                {tags && <div className="row ml--0 tags">
                                    {tags.map((tag) =>
                            <div className="tag">
                                {tag}
                            </div>)}
                                    </div>}
                            {path &&
                            <div className="button-group mt--20">
                                <Button path={path}>
                                    <span>VIEW PROJECT</span>
                                    <ChevronRight />
                                </Button>
                            </div>}
                        </div>
            </Modal.Body>
        </Modal>
    );
};

PortfolioModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    image: PropTypes.shape(ImageType).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape(TextType)),
};

export default PortfolioModal;
